export const environment = {
  production: true,
  base_url: 'https://coregateway.app.dlight.com',
  graph_url: "https://graphql.app.dlight.com/graphql",
  api_ver: 'api/v1',
  version: 'V4.1.0.908-Beta',
  name: 'prod',
  sentry_enabled: true,
  flexmonster_license: 'Z7BX-XH733R-720L0X-2C5X46-646301-1I6D1B-3Z0U4B-143W2D-1L1P23-23646W-003K4Q',
  base_web_url: 'https://atlas.app.dlight.com',
};
